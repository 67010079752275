import * as $ from 'jquery/dist/jquery.js';
import 'jquery-validation/dist/jquery.validate.js';

$(document).ready(function(){

  $('#login_form').validate({
    errorElement: "label",
    errorClass: "error badge badge-danger form-error-msg mt-2",
    rules:{
      "user[username]": {
        required: true,
      },
      "user[password]": {
        required: true
      }
    },
    highlight: function (element, errorClass, validClass) {
      $(element).addClass('border');
      $(element).addClass('border-danger');
    },
    unhighlight: function (element, errorClass, validClass) {
      $(element).removeClass('border');
      $(element).removeClass('border-danger');
    },
    errorPlacement: function(error, element) {
      error.appendTo(element.parents(".form-group"));
    },
    messages: {
      "user[username]": {
        required: "ID is required."
      },
      "user[password]": {
        required: "Password is required."
      }
    }

  });

});